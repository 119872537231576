import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

const initialState = { passScheduleArray: [], stations: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case 'setPassArray':
      return { ...state, passScheduleArray: payload };
    case 'setStations':
      return { ...state, stations: payload };
    default:
      throw new Error(`Unknown action ${type}.`);
  }
}

const predictorPath = `rg/${process.env.GATSBY_RESOURCE_GROUP}/@c3s/orbit-prediction-provider`;
const trackingStationCRUDPath = `rg/${process.env.GATSBY_RESOURCE_GROUP}/@c3s/tracking-station-crud`;

export default function usePassTableHelper() {
  const { enqueueSnackbar } = useSnackbar();
  const { dsClient, rpcWithErrorHandler } = useContext(DeepstreamContext);

  const [{ passScheduleArray, stations }, dispatch] = useReducer(reducer, initialState);

  const triggerPeriodicSchedule = useCallback(async () => {
    const { status } = await rpcWithErrorHandler(`${predictorPath}/triggerPeriodicSchedule`, null);
    if (status === 'success') {
      enqueueSnackbar('Triggered periodic pass schedule update.', { variant: 'info' });
    }
  }, [rpcWithErrorHandler, enqueueSnackbar]);

  const passRecord = useMemo(() => dsClient.record.getRecord(`${predictorPath}/passSchedule`), [dsClient.record]);

  useEffect(() => {
    const fetchTrackingStations = async () => {
      const { status, result } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/listTrackingStations`, {});
      if (status === 'success') {
        dispatch({
          type: 'setStations',
          payload: Array.isArray(result) ? result : [],
        });
      }
    };

    fetchTrackingStations();
  }, [rpcWithErrorHandler]);

  useEffect(() => {
    const passSubscription = (data) => {
      dispatch({
        type: 'setPassArray',
        payload: Array.isArray(data) ? data.map((item) => ({ id: dsClient.getUid(), ...item })) : [],
      });
    };

    passRecord.subscribe('passSchedule', passSubscription, true);

    return function cleanup() {
      passRecord.unsubscribe('passSchedule', passSubscription);
    };
  }, [passRecord, dsClient]);

  return {
    stations,
    passScheduleArray,
    triggerPeriodicSchedule,
  };
}
