import { Box, Button } from '@material-ui/core';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@material-ui/data-grid';
import CachedIcon from '@material-ui/icons/Cached';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';

import columns from './PassScheduleTableOptions';
import usePassTableHelper from './usePassTableHelper';

const PassScheduleTable = ({ selection, setSelection }) => {
  const { passScheduleArray, triggerPeriodicSchedule } = usePassTableHelper();
  const [sortModel, setSortModel] = useState([
    {
      field: 'aos',
      sort: 'asc',
    },
  ]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Button onClick={() => triggerPeriodicSchedule()} size="small" color="primary" startIcon={<CachedIcon />}>
          Refresh
        </Button>
      </GridToolbarContainer>
    );
  }

  return (
    <Box py={1}>
      <DataGrid
        rows={passScheduleArray.map((pass) => ({
          id: pass.id,
          station: pass?.trackingStation?.name ?? 'N/A',
          sat: pass?.tle?.title?.substring(2) ?? 'N/A',
          aos: pass.aos.date,
          los: pass.los.date,
          maxEl: Number.parseFloat(pass.maxEl.elevation).toFixed(2),
          duration: DateTime.fromMillis(pass.los.date).diff(DateTime.fromMillis(pass.aos.date)).as('seconds'),
        }))}
        columns={columns}
        density="compact"
        rowHeight={32}
        autoHeight
        components={{
          Toolbar: CustomToolbar,
        }}
        checkboxSelection
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onSelectionModelChange={(selectionModel) => {
          const selectedPasses = passScheduleArray.filter((pass) => selectionModel.includes(pass.id));
          setSelection(selectedPasses);
        }}
        selectionModel={selection.map(({ id }) => id)}
      />
    </Box>
  );
};

PassScheduleTable.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelection: PropTypes.func.isRequired,
};

export default memo(PassScheduleTable);
