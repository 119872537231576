import SatTracker from '@c3s/resium-sat-tracker';
import PropTypes from 'prop-types';
import React from 'react';

import useSatTrackerHelper from './useSatTrackerHelper';

const SatTrackerHelper = ({ selection }) => {
  const { stations, satellites } = useSatTrackerHelper(selection);

  return <SatTracker facilities={stations} satellites={satellites} />;
};

SatTrackerHelper.propTypes = {
  selection: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SatTrackerHelper;
