import getSunlightIntervals from '@c3s/resium-sat-tracker/dist/src/utils/cesium/getSunlightIntervals';
import Bluebird from 'bluebird';
import { useContext, useEffect, useMemo, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';
import useSnackbar from '#hooks/useSnackbar';

import RADCUBEModelURL from '../../../assets/RADCUBE.glb';

const initialState = { satellites: [], stations: [] };

function reducer(state, { type, payload }) {
  switch (type) {
    case 'setSatellites':
      return { ...state, satellites: payload };
    case 'setStations':
      return { ...state, stations: payload };
    default:
      throw new Error(`Unknown action ${type}.`);
  }
}

const predictorPath = `rg/${process.env.GATSBY_RESOURCE_GROUP}/@c3s/orbit-prediction-provider`;
const trackingStationCRUDPath = `rg/${process.env.GATSBY_RESOURCE_GROUP}/@c3s/tracking-station-crud`;

export default function useSatTrackerHelper(selection) {
  const { dsClient, rpcWithErrorHandler } = useContext(DeepstreamContext);
  const { enqueueSnackbar } = useSnackbar();

  const trackingStationsRecord = useMemo(() => dsClient.record.getRecord(`${trackingStationCRUDPath}/`), [dsClient]);

  const [{ satellites, stations }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const generateSatelliteTrajectories = async (selectedArray) => {
      try {
        const sats = await Bluebird.map(selectedArray, async ({ trackingStation, tle, aos, los }) => {
          const { status, result } = await rpcWithErrorHandler(`${predictorPath}/getTrajectoryForTimeRange`, {
            tle,
            observer: trackingStation.observer,
            startDate: aos.date,
            endDate: los.date,
            precision: 60000, // 1 min
          });
          if (status === 'success') {
            const satCartographicPoints = result.trajectory.map((x, i) => {
              const currentTimeStamp = aos.date + i * 60000;
              return {
                date: currentTimeStamp,
                long: x.positionGd.longitude,
                lat: x.positionGd.latitude,
                height: x.positionGd.altitude * 1000,
                sunlight: x.sunlight,
              };
            });

            return {
              name: tle?.title?.substring(2) ?? 'N/A',
              startDate: new Date(aos.date).toISOString(),
              endDate: new Date(los.date).toISOString(),
              trajectoryColor: [255, 255, 255],
              trajectoryPoints: satCartographicPoints,
              model: {
                gltf: RADCUBEModelURL,
                scale: 512,
                minimumPixelSize: 128,
                color: getSunlightIntervals(satCartographicPoints),
              },
            };
          }
          return {}; // FIXME: handle status !== success correctly
        });

        dispatch({ type: 'setSatellites', payload: sats });
      } catch (err) {
        enqueueSnackbar(err, { variant: 'error' });
      }
    };

    generateSatelliteTrajectories(selection);
  }, [selection, rpcWithErrorHandler, enqueueSnackbar]);

  useEffect(() => {
    const fetchTrackingStations = async () => {
      const { status, result } = await rpcWithErrorHandler(`${trackingStationCRUDPath}/listTrackingStations`, {});
      if (status === 'success') {
        dispatch({
          type: 'setStations',
          payload: Array.isArray(result)
            ? result.map(({ name, position: { latitude, longitude, altitude } }) => ({
                name,
                position: { cartographicDegrees: [longitude, latitude, altitude] },
                sphereColor: [255 * Math.random(), 255 * Math.random(), 255 * Math.random()],
              }))
            : [],
        });
      }
    };

    trackingStationsRecord.subscribe(fetchTrackingStations);

    fetchTrackingStations();

    return function cleanup() {
      trackingStationsRecord.unsubscribe(fetchTrackingStations);
    };
  }, [rpcWithErrorHandler, dsClient, trackingStationsRecord]);

  return {
    satellites,
    stations,
  };
}
