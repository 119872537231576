import React from 'react';
import { Helmet } from 'react-helmet';

import SatTrackerPageWrapper from '#components/page-content/sat-tracker';

const PageTitle = 'Dashboard';

const IndexPage = () => {
  return (
    <>
      <Helmet titleTemplate="MOC | %s">
        <title>{PageTitle}</title>
      </Helmet>
      <SatTrackerPageWrapper />
    </>
  );
};

export default IndexPage;
