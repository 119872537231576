import useTimeAgo from '@rooks/use-time-ago';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

function TimeDisplay({ date }) {
  const ago = useTimeAgo(date);
  return `${DateTime.fromMillis(date).toJSDate().toLocaleString()} (${ago})`;
}
TimeDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.objectOf(DateTime)]).isRequired,
};

export default [
  {
    headerName: 'Station',
    field: 'station',
    flex: 1,
  },
  {
    headerName: 'Satellite',
    field: 'sat',
    flex: 1,
  },
  {
    headerName: 'AOS',
    field: 'aos',
    filterable: false,
    flex: 1,
    valueFormatter: ({ value }) => DateTime.fromMillis(value).toISO(),
    // eslint-disable-next-line react/destructuring-assignment
    renderCell: (params) => <TimeDisplay date={params.value} />,
  },
  {
    headerName: 'LOS',
    field: 'los',
    filterable: false,
    flex: 1,
    valueFormatter: ({ value }) => DateTime.fromMillis(value).toISO(),
    // eslint-disable-next-line react/destructuring-assignment
    renderCell: (params) => <TimeDisplay date={params.value} />,
  },
  {
    headerName: 'Max Elevation (°)',
    field: 'maxEl',
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Duration (s)',
    field: 'duration',
    filterable: false,
    flex: 0.5,
  },
];
