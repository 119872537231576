import React, { useState } from 'react';

import PassScheduleTable from '#components/page-content/sat-tracker/PassScheduleTable';
import SatTrackerHelper from '#components/page-content/sat-tracker/SatTrackerHelper';

const SatTrackerPageWrapper = () => {
  const [selection, setSelection] = useState([]);

  return (
    <>
      <SatTrackerHelper selection={selection} />
      <PassScheduleTable selection={selection} setSelection={setSelection} />
    </>
  );
};

export default SatTrackerPageWrapper;
